import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useParams
} from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import {
  MdWeekDate,
  User,
  UserRole,
} from '../../api-client';

import {
  useDestocking
} from '../../hooks';

import {
  Button,
  CheckBox,
  ErrorMessagePanel,
  LoadingSpinner,
  OrderSummaryTable,
  PageHeader,
  TextBox,
  Toast,
  Tooltip,
} from '../../components/atoms';

import {
  OrderConfirmationModal,
  StopAdjustmentAlertModal,
} from '../../components/molecules';

import {
  ProductPanel
} from '../../components/organisms';

import {
  BackToTopLinkContainer,
  ButtonsContainer,
  ContentsContainer,
  ContentsContainerOuter,
  EmptyOrderSummaryMessage,
  EmptySKUsMessage,
  ErrorMessagePanelContainer,
  LeadtimeFormLabel,
  LeadtimeFormsContainer,
  LoadingSpinnerContainer,
  OrderButtonContainer,
  OrderSummaryTableContainer,
  ProductPanelContainer,
  ProductPanelContainerOuter,
  RecalculatingMessage,
  RecalculatingMessageContainer,
  SectionName,
  TextBoxContainer,
  ToastContainer,
} from './style';

type ValidationErrorInventoryPlanPerMdWeek = {
  [mdWeekYearNumStr: string]: {
    shipmentQuantity: boolean
    confirmedOrderQuantity: boolean
    storeInventoryQuantityStandard: boolean
    expectedSalesAmountByUser: boolean
  }
};

type ValidationError = {
  [productCode: string]: {
    inventoryPlanPerMdWeek: ValidationErrorInventoryPlanPerMdWeek
  };
};

type LastEditedNCycleParameter = 'leadtime' | 'number-of-order-cycles' | 'number-of-order-cycle-weeks';

interface DestockingPathParams {
  orderGroupId: string
}

export interface DestockingProps {
  user: User
}

const mdWeekYearNumStr = (mdWeekDate: MdWeekDate) => `${mdWeekDate.mdYear}-${mdWeekDate.mdWeekNum}`;

// リードタイムの最大有効値は (52 - (サイクル回数 * サイクル週数)) * 7
const calcMaxLeadtime = (
  numberOfOrderCycles: number,
  numberOfOrderCycleWeeks: number,
) => (52 - (numberOfOrderCycles * numberOfOrderCycleWeeks)) * 7;

// サイクル回数の最大有効値は (52 - (リードタイム / 7)) / サイクル週数
// リードタイムが7で割り切れない場合は切り上げる
// サイクル週数で割って出た余りは切り捨てる（合計52を超えないようにする）
const calcMaxNumberOfOrderCycles = (
  leadtime: number,
  numberOfOrderCycleWeeks: number,
) => Math.floor((52 - Math.ceil(leadtime / 7)) / numberOfOrderCycleWeeks);

// サイクル週数の最大有効値は (52 - ((リードタイム / 7)) / サイクル回数
// リードタイムが7で割り切れない場合は切り上げる
// サイクル回数で割って出た余りは切り捨てる（合計52を超えないようにする）
const calcMaxNumberOfOrderCycleWeeks = (
  leadtime: number,
  numberOfOrderCycles: number,
) => Math.floor((52 - Math.ceil(leadtime / 7)) / numberOfOrderCycles);

const isValidLeadtime = (
  leadtime: number,
  numberOfOrderCycles: number,
  numberOfOrderCycleWeeks: number,
) => leadtime >= 1 && leadtime <= calcMaxLeadtime(numberOfOrderCycles, numberOfOrderCycleWeeks);

const isValidNumberOfOrderCycles = (
  leadtime: number,
  numberOfOrderCycles: number,
  numberOfOrderCycleWeeks: number,
) => numberOfOrderCycles >= 1 && numberOfOrderCycles <= calcMaxNumberOfOrderCycles(leadtime, numberOfOrderCycleWeeks);

const isValidNumberOfOrderCycleWeeks = (
  leadtime: number,
  numberOfOrderCycles: number,
  numberOfOrderCycleWeeks: number,
) => numberOfOrderCycleWeeks >= 1 && numberOfOrderCycleWeeks <= calcMaxNumberOfOrderCycleWeeks(leadtime, numberOfOrderCycles);

export const Destocking: React.FC<DestockingProps> = ({
  user
}) => {
  const { orderGroupId } = useParams<DestockingPathParams>();
  const orderGroupIdNum = Number.parseInt(orderGroupId);
  const {
    destocking,
    statusCode,
    putDestocking,
    putDestockingOrder,
    getCSVFile,
    putDestockingOrderGroupIncomingMdWeekBackward,
    putDestockingOrderGroupIncomingMdWeekForward,
    getDestocking,
    updateSkuMeta,
  } = useDestocking(orderGroupIdNum);

  // 再レンダリングを行いたい場合に使う.
  // 全ての state を更新していると再レンダリングの負荷がかかりすぎるので、必要な時にのみ実行すること.
  const forceUpdate = useState(false)[1];

  const orderGroupRef = useRef(cloneDeep(destocking.orderGroup));
  useEffect(() => {
    orderGroupRef.current = cloneDeep(destocking.orderGroup);
    forceUpdate(v => !v);
  }, [destocking.orderGroup, forceUpdate]);

  const orderDetailRef = useRef(cloneDeep(destocking.orderDetail));
  const validationErrorRef = useRef<ValidationError>({});
  useEffect(() => {
    orderDetailRef.current = cloneDeep(destocking.orderDetail);

    const validationError: ValidationError = {};
    destocking.orderDetail?.products.forEach((product) => {
      const inventoryPlanPerMdWeek: ValidationErrorInventoryPlanPerMdWeek = {};
      Object.keys(product.inventoryPlanPerMdWeek).forEach((mdWeek) => {
        inventoryPlanPerMdWeek[mdWeek] = {
          shipmentQuantity: false,
          confirmedOrderQuantity: false,
          storeInventoryQuantityStandard: false,
          expectedSalesAmountByUser: false,
        };
      });
      validationError[product.code] = {
        inventoryPlanPerMdWeek
      };
    });
    validationErrorRef.current = cloneDeep(validationError);
    forceUpdate(v => !v);
  }, [destocking.orderDetail, forceUpdate]);

  const [highlightedColIndex, setHighlightedColIndex] = useState(-1);

  const hasChange = () =>
    (destocking.orderGroup != null && orderGroupRef != null && JSON.stringify(destocking.orderGroup) !== JSON.stringify(orderGroupRef.current)) ||
    (destocking.orderDetail != null && orderDetailRef.current != null && JSON.stringify(destocking.orderDetail) !== JSON.stringify(orderDetailRef.current));

  const lastEditedNCycleParameter = useRef<LastEditedNCycleParameter>('leadtime');

  const hasValidationError = () =>
    (orderGroupRef.current != null && isValidLeadtime(orderGroupRef.current.leadtime, orderGroupRef.current.numberOfOrderCycles, orderGroupRef.current.numberOfOrderCycleWeeks) === false) ||
    (orderGroupRef.current != null && isValidNumberOfOrderCycles(orderGroupRef.current.leadtime, orderGroupRef.current.numberOfOrderCycles, orderGroupRef.current.numberOfOrderCycleWeeks) === false) ||
    (orderGroupRef.current != null && isValidNumberOfOrderCycleWeeks(orderGroupRef.current.leadtime, orderGroupRef.current.numberOfOrderCycles, orderGroupRef.current.numberOfOrderCycleWeeks) === false) ||
    Object.values(validationErrorRef.current).some((product) =>
      Object.values(product.inventoryPlanPerMdWeek).some((inventoryPlan) =>
        Object.values(inventoryPlan).some((hasError) => hasError)
      )
    );

  const [orderConfirmationModalVisible, setOrderConfirmationModalVisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [recalculationMessageVisible, setRecalculationMessageVisible] = useState(false);
  const [stopAdjustmentAlertModalVisible, setStopAdjustmentAlertModalVisible] = useState(false);
  const [adjustmentAlertDate, setAdjustmentAlertDate] = useState('');
  const [adjustmentAlertProduceCode, setAdjustmentAlertProduceCode] = useState('');

  const openOrderConfirmationModal = () => {
    if (hasChange() === true) {
      return;
    }
    setOrderConfirmationModalVisible(true);
  };

  const handleClickSubCategoryPredictionCheckbox = async (value: boolean) => {
    if (!orderGroupRef.current || !destocking.orderGroup || !destocking.orderDetail) {
      return;
    }
    
    setRecalculationMessageVisible(true);
    destocking.orderGroup.useSubcategoryPrediction = value;
    await putDestocking(
      {
        ...destocking.orderGroup,
        leadtime: orderGroupRef.current != null ? orderGroupRef.current.leadtime : destocking.orderGroup.leadtime,
        numberOfOrderCycles: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycles : destocking.orderGroup.numberOfOrderCycles,
        numberOfOrderCycleWeeks: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycleWeeks : destocking.orderGroup.numberOfOrderCycleWeeks,
      },
      orderDetailRef.current != null ? orderDetailRef.current : destocking.orderDetail
    )?.then(() => setRecalculationMessageVisible(false)
    )?.catch((error) => {
      console.log('error', error); //暫定
      setRecalculationMessageVisible(false);
    });
  };

  if (statusCode != null && statusCode !== 200) {
    return (
      <ErrorMessagePanelContainer>
        <ErrorMessagePanel statusCode={statusCode} />
      </ErrorMessagePanelContainer>
    );
  } else {
    return (
      <>
        {stopAdjustmentAlertModalVisible === true ? (
          <StopAdjustmentAlertModal
            disabled={hasValidationError()}
            onSubmitHandler={() => {
              if (orderDetailRef.current != null) {
                const productIndex = orderDetailRef.current.products.findIndex(({ code }) => code === adjustmentAlertProduceCode);
                orderDetailRef.current.products[productIndex].inventoryPlanPerMdWeek[adjustmentAlertDate].isAdjustmentAlertStopped = true;
              }
              if (destocking.orderGroup != null && destocking.orderDetail != null && orderDetailRef.current != null && hasChange() === true && hasValidationError() === false) {
                setRecalculationMessageVisible(true);
                putDestocking(
                  destocking.orderGroup,
                  orderDetailRef.current != null ? orderDetailRef.current : destocking.orderDetail
                )?.then(() => {
                  setStopAdjustmentAlertModalVisible(false);
                  setRecalculationMessageVisible(false);
                });
              }
            }}
            onCloseHandler={() => {
              setStopAdjustmentAlertModalVisible(false);
            }}
          />
        ) : null}
        <ContentsContainerOuter>
          <PageHeader
            pageTitle={orderGroupRef.current != null ? `${orderGroupRef.current.name}（${orderGroupRef.current.supplierName != null ? orderGroupRef.current.supplierName : '-'}）` : ''}
            backLink={true}
          />
          {toastVisible === true ? (
            <ToastContainer>
              <Toast
                message='発注登録しました。'
                onCloseHandler={() => setTimeout(() => setToastVisible(false), 300)}
              />
            </ToastContainer>
          ) : null}
          <ButtonsContainer>
            <React.Fragment>
              <Button
                styleType='secondary'
                label='ダウンロード'
                width={130}
                disabled={recalculationMessageVisible === true}
                onClickHandler={() => {
                  getCSVFile(orderGroupIdNum)
                    ?.then(({ filename, blob }) => {
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', filename != null ? filename : '');
                      document.body.appendChild(link);
                      link.click();
                      link.parentNode?.removeChild(link);
                    });
                }}
                data-testid='csv-download-button'
              />
              {user.role === UserRole.Operator ? (
                <OrderButtonContainer>
                  <Button
                    styleType='primary'
                    label='発注'
                    width={76}
                    disabled={orderGroupRef.current == null || orderDetailRef.current == null || hasChange() === true}
                    onClickHandler={() => openOrderConfirmationModal()}
                    data-testid='open-order-confirmation-modal-button'
                  />
                  {orderGroupRef.current != null && orderConfirmationModalVisible === true ? (
                    <OrderConfirmationModal
                      onCloseHandler={() => setOrderConfirmationModalVisible(false)}
                      onSubmitHandler={() => {
                        if (orderGroupRef.current == null) {
                          return;
                        }
                        putDestockingOrder(orderGroupRef.current)
                          ?.then(() => setToastVisible(true));
                        setOrderConfirmationModalVisible(false);
                      }}
                    />
                  ) : null}
                </OrderButtonContainer>
              ) : null}
            </React.Fragment>
          </ButtonsContainer>
          <LeadtimeFormsContainer>
            {orderGroupRef.current != null ? (
              <React.Fragment>
                <LeadtimeFormLabel>サブカテゴリ予測：</LeadtimeFormLabel>
                <TextBoxContainer>
                  <CheckBox
                    id='subcategory-prediction-checkbox'
                    size={35}
                    defaultValue={destocking.orderGroup?.useSubcategoryPrediction}
                    disabled={user.role === UserRole.Manager || recalculationMessageVisible === true}
                    onChangeHandler={handleClickSubCategoryPredictionCheckbox}
                    setUncheckedMarkColor={true}
                  />
                </TextBoxContainer>
                <LeadtimeFormLabel>リードタイム(日)：</LeadtimeFormLabel>
                <TextBoxContainer>
                  <TextBox
                    id='leadtime-input'
                    type='number'
                    width={55}
                    height={40}
                    defaultValue={destocking.orderGroup != null ? destocking.orderGroup.leadtime.toString() : ''}
                    disabled={user.role === UserRole.Manager || recalculationMessageVisible === true}
                    required={true}
                    min={1}
                    max={calcMaxLeadtime(orderGroupRef.current.numberOfOrderCycles, orderGroupRef.current.numberOfOrderCycleWeeks)}
                    forceValidate={true}
                    suppressErrorMessage={true}
                    highlightOnChange={true}
                    onChangeHandler={(value) => {
                      if (orderGroupRef.current == null) {
                        return;
                      }
                      const maybeLeadtime = Number.parseInt(value);
                      const leadtime = Number.isNaN(maybeLeadtime) ? 0 : maybeLeadtime;
                      if (orderGroupRef.current.leadtime !== leadtime) {
                        orderGroupRef.current.leadtime = leadtime;
                        lastEditedNCycleParameter.current = 'leadtime';
                      }
                    }}
                    onBlurHandler={() => {
                      forceUpdate(v => !v);
                      if (recalculationMessageVisible) return;
                      if (destocking.orderGroup != null && orderGroupRef.current != null && destocking.orderDetail != null && orderDetailRef.current != null && hasChange() === true && hasValidationError() === false) {
                        setRecalculationMessageVisible(true);
                        putDestocking(
                          {
                            ...destocking.orderGroup,
                            leadtime: orderGroupRef.current != null ? orderGroupRef.current.leadtime : destocking.orderGroup.leadtime,
                            numberOfOrderCycles: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycles : destocking.orderGroup.numberOfOrderCycles,
                            numberOfOrderCycleWeeks: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycleWeeks : destocking.orderGroup.numberOfOrderCycleWeeks,
                          },
                          orderDetailRef.current != null ? orderDetailRef.current : destocking.orderDetail
                        )?.then(() => setRecalculationMessageVisible(false));
                      }
                    }}
                  />
                  {orderGroupRef.current != null && (orderGroupRef.current.leadtime < 1 || (lastEditedNCycleParameter.current === 'leadtime' && orderGroupRef.current.leadtime > calcMaxLeadtime(orderGroupRef.current.numberOfOrderCycles, orderGroupRef.current.numberOfOrderCycleWeeks))) ? (
                    <Tooltip
                      id='leadtime-validation-error-tooltip'
                      text='合計52週の半角数字で入力して下さい。'
                      top={45}
                      width={262}
                    />
                  ) : null}
                </TextBoxContainer>
                <LeadtimeFormLabel>サイクル回数(週)：</LeadtimeFormLabel>
                <TextBoxContainer>
                  <TextBox
                    id='number-of-order-cycles-input'
                    type='number'
                    width={55}
                    height={40}
                    defaultValue={destocking.orderGroup != null ? destocking.orderGroup.numberOfOrderCycles.toString() : ''}
                    disabled={user.role === UserRole.Manager || recalculationMessageVisible === true}
                    required={true}
                    min={1}
                    max={calcMaxNumberOfOrderCycles(orderGroupRef.current.leadtime, orderGroupRef.current.numberOfOrderCycleWeeks)}
                    suppressErrorMessage={true}
                    forceValidate={true}
                    highlightOnChange={true}
                    onChangeHandler={(value) => {
                      if (orderGroupRef.current == null) {
                        return;
                      }
                      const maybeNumberOfOrderCycles = Number.parseInt(value);
                      const numberOfOrderCycles = Number.isNaN(maybeNumberOfOrderCycles) ? 0 : maybeNumberOfOrderCycles;
                      if (orderGroupRef.current.numberOfOrderCycles !== numberOfOrderCycles) {
                        orderGroupRef.current.numberOfOrderCycles = numberOfOrderCycles;
                        lastEditedNCycleParameter.current = 'number-of-order-cycles';
                      }
                    }}
                    onBlurHandler={() => {
                      forceUpdate(v => !v);
                      if (recalculationMessageVisible) return;
                      if (destocking.orderGroup != null && orderGroupRef.current != null && destocking.orderDetail != null && orderDetailRef.current != null && hasChange() === true && hasValidationError() === false) {
                        setRecalculationMessageVisible(true);
                        putDestocking(
                          {
                            ...destocking.orderGroup,
                            leadtime: orderGroupRef.current != null ? orderGroupRef.current.leadtime : destocking.orderGroup.leadtime,
                            numberOfOrderCycles: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycles : destocking.orderGroup.numberOfOrderCycles,
                            numberOfOrderCycleWeeks: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycleWeeks : destocking.orderGroup.numberOfOrderCycleWeeks,
                          },
                          orderDetailRef.current != null ? orderDetailRef.current : destocking.orderDetail
                        )?.then(() => setRecalculationMessageVisible(false));
                      }
                    }}
                  />
                  {orderGroupRef.current != null && (orderGroupRef.current.numberOfOrderCycles < 1 || (lastEditedNCycleParameter.current === 'number-of-order-cycles' && orderGroupRef.current.numberOfOrderCycles > calcMaxNumberOfOrderCycles(orderGroupRef.current.leadtime, orderGroupRef.current.numberOfOrderCycleWeeks))) ? (
                    <Tooltip
                      id='number-of-order-cycles-validation-error-tooltip'
                      text='合計52週の半角数字で入力して下さい。'
                      top={45}
                      width={262}
                    />
                  ) : null}
                </TextBoxContainer>
                <LeadtimeFormLabel>サイクル週数(週)：</LeadtimeFormLabel>
                <TextBoxContainer>
                  <TextBox
                    id='number-of-order-cycle-weeks-input'
                    type='number'
                    height={40}
                    width={55}
                    defaultValue={destocking.orderGroup != null ? destocking.orderGroup.numberOfOrderCycleWeeks.toString() : ''}
                    disabled={user.role === UserRole.Manager || recalculationMessageVisible === true}
                    required={true}
                    min={1}
                    max={calcMaxNumberOfOrderCycleWeeks(orderGroupRef.current.leadtime, orderGroupRef.current.numberOfOrderCycles)}
                    suppressErrorMessage={true}
                    forceValidate={true}
                    highlightOnChange={true}
                    onChangeHandler={(value) => {
                      if (orderGroupRef.current == null) {
                        return;
                      }
                      const maybeNumberOfOrderCycleWeeks = Number.parseInt(value);
                      const numberOfOrderCycleWeeks = Number.isNaN(maybeNumberOfOrderCycleWeeks) ? 0 : maybeNumberOfOrderCycleWeeks;
                      if (orderGroupRef.current.numberOfOrderCycleWeeks !== numberOfOrderCycleWeeks) {
                        orderGroupRef.current.numberOfOrderCycleWeeks = numberOfOrderCycleWeeks;
                        lastEditedNCycleParameter.current = 'number-of-order-cycle-weeks';
                      }
                    }}
                    onBlurHandler={() => {
                      forceUpdate(v => !v);
                      if (recalculationMessageVisible) return;
                      if (destocking.orderGroup != null && orderGroupRef.current != null && destocking.orderDetail != null && orderDetailRef.current != null && hasChange() === true && hasValidationError() === false) {
                        setRecalculationMessageVisible(true);
                        putDestocking(
                          {
                            ...destocking.orderGroup,
                            leadtime: orderGroupRef.current != null ? orderGroupRef.current.leadtime : destocking.orderGroup.leadtime,
                            numberOfOrderCycles: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycles : destocking.orderGroup.numberOfOrderCycles,
                            numberOfOrderCycleWeeks: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycleWeeks : destocking.orderGroup.numberOfOrderCycleWeeks,
                          },
                          orderDetailRef.current != null ? orderDetailRef.current : destocking.orderDetail
                        )?.then(() => setRecalculationMessageVisible(false));
                      }
                    }}
                  />
                  {orderGroupRef.current != null && (orderGroupRef.current.numberOfOrderCycleWeeks < 1 || (lastEditedNCycleParameter.current === 'number-of-order-cycle-weeks' && orderGroupRef.current.numberOfOrderCycleWeeks > calcMaxNumberOfOrderCycleWeeks(orderGroupRef.current.leadtime, orderGroupRef.current.numberOfOrderCycles))) ? (
                    <Tooltip
                      id='number-of-order-cycle-weeks-validation-error-tooltip'
                      text='合計52週の半角数字で入力して下さい。'
                      top={45}
                      width={262}
                    />
                  ) : null}
                </TextBoxContainer>
              </React.Fragment>
            ) : null}
          </LeadtimeFormsContainer>
          <ContentsContainer>
            <SectionName>発注サマリー</SectionName>
            {destocking.orderSummary != null ?
              destocking.orderSummary.orderMdWeekDates.length === 0 ? (
                <EmptyOrderSummaryMessage>入庫予定がありません。</EmptyOrderSummaryMessage>
              ) : (
                <OrderSummaryTableContainer>
                  <OrderSummaryTable
                    orderSummary={destocking.orderSummary}
                    isIncomingWeekUpdatable={user.role !== UserRole.Manager && !hasValidationError() && !recalculationMessageVisible}
                    onUpdateIncomingMdWeekBackward={(mdWeek: number, mdYear: number, date: Date) => {
                      if (destocking.orderGroup != null) {
                        setRecalculationMessageVisible(true);
                        putDestockingOrderGroupIncomingMdWeekBackward(destocking.orderGroup, mdWeek, mdYear, date)?.then(() => {
                          setRecalculationMessageVisible(false);
                        });
                      }
                    }}
                    onUpdateIncomingMdWeekForward={(mdWeek: number, mdYear: number, date: Date) => {
                      if (destocking.orderGroup != null) {
                        setRecalculationMessageVisible(true);
                        putDestockingOrderGroupIncomingMdWeekForward(destocking.orderGroup, mdWeek, mdYear, date)?.then(() => {
                          setRecalculationMessageVisible(false);
                        });
                      }
                    }}
                  />
                </OrderSummaryTableContainer>
              ) : <LoadingSpinnerContainer><LoadingSpinner /></LoadingSpinnerContainer>}
            <SectionName>SKU一覧</SectionName>
            {orderGroupRef.current != null && destocking.orderDetail != null ? (
              <>
                {destocking.orderDetail.products.length === 0 ? (
                  <EmptySKUsMessage>SKUが登録されていません。</EmptySKUsMessage>
                ) : destocking.orderDetail.products.map((product, index) => {
                  if (orderGroupRef.current == null) {
                    return null;
                  }
                  if (orderDetailRef.current == null) {
                    return null;
                  }
                  return (
                    <ProductPanelContainerOuter key={index}>
                      <ProductPanelContainer id={`product-panel-${product.code}`}>
                        <ProductPanel
                          product={product}
                          pastMdWeekDates={orderDetailRef.current.pastMdWeekDates}
                          presentAndFutureMdWeekDates={orderDetailRef.current.presentAndFutureMdWeekDates}
                          highlightedColIndex={highlightedColIndex}
                          readonly={user.role === UserRole.Manager || recalculationMessageVisible === true}
                          isStoppableAdjustmentAlert={!hasValidationError()}
                          orderGroupId={destocking.orderGroup?.id ?? 0}
                          onShortageToleranceRankChangeHandler={(shortageToleranceRank) => {
                            if (orderDetailRef.current == null) {
                              return null;
                            }
                            if (orderDetailRef.current.products[index].shortageToleranceRank === shortageToleranceRank) {
                              return;
                            }
                            orderDetailRef.current.products[index].shortageToleranceRank = shortageToleranceRank;
                            if (destocking.orderGroup != null && orderGroupRef.current != null && destocking.orderDetail != null && orderDetailRef.current != null && hasChange() === true && hasValidationError() === false) {
                              setRecalculationMessageVisible(true);
                              putDestocking(
                                {
                                  ...destocking.orderGroup,
                                  leadtime: orderGroupRef.current != null ? orderGroupRef.current.leadtime : destocking.orderGroup.leadtime,
                                  numberOfOrderCycles: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycles : destocking.orderGroup.numberOfOrderCycles,
                                  numberOfOrderCycleWeeks: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycleWeeks : destocking.orderGroup.numberOfOrderCycleWeeks,
                                },
                                orderDetailRef.current != null ? orderDetailRef.current : destocking.orderDetail
                              )?.then(() => setRecalculationMessageVisible(false));
                            }
                          }}
                          onShipmentReasonChangeHandler={(mdWeekDate, shipmentReason) => {
                            const wy = mdWeekYearNumStr(mdWeekDate);
                            if (!orderDetailRef?.current?.products[index]) return;
                            if (shipmentReason != null && product.inventoryPlanPerMdWeek[wy].shipmentReason !== shipmentReason) {
                              orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].shipmentReason = shipmentReason;
                            } else {
                              return;
                            }

                            if (destocking.orderGroup != null && orderGroupRef.current != null && destocking.orderDetail != null && orderDetailRef.current != null && hasChange() === true && hasValidationError() === false) {
                              setRecalculationMessageVisible(true);
                              putDestocking(
                                {
                                  ...destocking.orderGroup,
                                  leadtime: orderGroupRef.current != null ? orderGroupRef.current.leadtime : destocking.orderGroup.leadtime,
                                  numberOfOrderCycles: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycles : destocking.orderGroup.numberOfOrderCycles,
                                  numberOfOrderCycleWeeks: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycleWeeks : destocking.orderGroup.numberOfOrderCycleWeeks,
                                },
                                orderDetailRef.current != null ? orderDetailRef.current : destocking.orderDetail
                              )?.then(() => setRecalculationMessageVisible(false));
                            }
                          }}
                          onShipmentQuantityChangeHandler={(mdWeekDate, quantity, hasError) => {
                            const wy = mdWeekYearNumStr(mdWeekDate);
                            if (validationErrorRef.current[product.code].inventoryPlanPerMdWeek[wy].shipmentQuantity !== hasError) {
                              validationErrorRef.current[product.code].inventoryPlanPerMdWeek[wy].shipmentQuantity = hasError;
                            }

                            if (orderDetailRef.current?.products[index] == null) {
                              return;
                            }

                            if (orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].shipmentQuantity !== quantity) {
                              orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].shipmentQuantity = quantity;
                            }
                          }}
                          onConfirmedOrderQuantityChangeHandler={(mdWeekDate, quantity, hasError) => {
                            const wy = mdWeekYearNumStr(mdWeekDate);
                            if (validationErrorRef.current[product.code].inventoryPlanPerMdWeek[wy].confirmedOrderQuantity !== hasError) {
                              validationErrorRef.current[product.code].inventoryPlanPerMdWeek[wy].confirmedOrderQuantity = hasError;
                            }

                            if (orderDetailRef.current?.products[index] == null) {
                              return;
                            }

                            if (orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].confirmedOrderQuantity !== quantity) {
                              orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].confirmedOrderQuantity = quantity;
                            }
                          }}
                          onStoreInventoryQuantityStandardChangeHandler={(mdWeekDate, quantity, hasError) => {
                            const wy = mdWeekYearNumStr(mdWeekDate);
                            if (validationErrorRef.current[product.code].inventoryPlanPerMdWeek[wy].storeInventoryQuantityStandard !== hasError) {
                              validationErrorRef.current[product.code].inventoryPlanPerMdWeek[wy].storeInventoryQuantityStandard = hasError;
                            }

                            if (orderDetailRef.current?.products[index] == null) {
                              return;
                            }

                            if (orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].storeInventoryQuantityStandard !== quantity) {
                              orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].storeInventoryQuantityStandard = quantity;
                            }
                          }}
                          onBlurInputHandler={() => {
                            if (recalculationMessageVisible) return;
                            if (destocking.orderGroup != null && orderGroupRef.current != null && destocking.orderDetail != null && orderDetailRef.current != null && hasChange() === true && hasValidationError() === false) {
                              setRecalculationMessageVisible(true);
                              putDestocking(
                                {
                                  ...destocking.orderGroup,
                                  leadtime: orderGroupRef.current != null ? orderGroupRef.current.leadtime : destocking.orderGroup.leadtime,
                                  numberOfOrderCycles: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycles : destocking.orderGroup.numberOfOrderCycles,
                                  numberOfOrderCycleWeeks: orderGroupRef.current != null ? orderGroupRef.current.numberOfOrderCycleWeeks : destocking.orderGroup.numberOfOrderCycleWeeks,
                                },
                                orderDetailRef.current != null ? orderDetailRef.current : destocking.orderDetail
                              )?.then(() => setRecalculationMessageVisible(false));
                            }
                          }}
                          onHighlightedColIndexChangeHandler={(colIdx) => {
                            if (colIdx === highlightedColIndex) {
                              // No highlight
                              setHighlightedColIndex(-1);
                            } else {
                              setHighlightedColIndex(colIdx);
                            }
                          }}
                          onClickStopAdjustmentAlertHandler={(date: string, productCode: string) => {
                            setStopAdjustmentAlertModalVisible(true);
                            setAdjustmentAlertDate(date);
                            setAdjustmentAlertProduceCode(productCode);
                          }}
                          getDestocking={getDestocking}
                          updateSkuMeta={updateSkuMeta}
                          onExpectedSalesAmountByUserChangeHandler={(mdWeekDate, quantity, hasError) => {
                            const wy = mdWeekYearNumStr(mdWeekDate);
                            if (validationErrorRef.current[product.code].inventoryPlanPerMdWeek[wy].expectedSalesAmountByUser !== hasError) {
                              validationErrorRef.current[product.code].inventoryPlanPerMdWeek[wy].expectedSalesAmountByUser = hasError;
                            }

                            if (orderDetailRef.current?.products[index] == null) {
                              return;
                            }

                            if (orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].expectedSalesAmountByUser !== quantity) {
                              orderDetailRef.current.products[index].inventoryPlanPerMdWeek[wy].expectedSalesAmountByUser = quantity;
                            }
                          }}
                        />
                        <BackToTopLinkContainer
                          onClick={() => {
                            // jsdom may not have scrollTo method. Ref: https://github.com/jsdom/jsdom/issues/1422
                            if (window.scrollTo != null) {
                              window.scrollTo(0, 0);
                            }
                          }}
                          data-testid='destocking-back-to-top-link'
                        >ページTOPに戻る</BackToTopLinkContainer>
                      </ProductPanelContainer>
                    </ProductPanelContainerOuter>
                  );
                })}
              </>
            ) : (<LoadingSpinnerContainer><LoadingSpinner /></LoadingSpinnerContainer>)}
          </ContentsContainer>
          {recalculationMessageVisible === true ? (
            <RecalculatingMessageContainer>
              <RecalculatingMessage>処理中</RecalculatingMessage>
            </RecalculatingMessageContainer>
          ) : null}
        </ContentsContainerOuter>
      </>
    );
  }
};
