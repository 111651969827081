/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for DOR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DemandAggregation,
    DemandAggregationFromJSON,
    DemandAggregationFromJSONTyped,
    DemandAggregationToJSON,
    InventoryPlan,
    InventoryPlanFromJSON,
    InventoryPlanFromJSONTyped,
    InventoryPlanToJSON,
    ModelType,
    ModelTypeFromJSON,
    ModelTypeFromJSONTyped,
    ModelTypeToJSON,
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
    ProductWithDetailAllOf,
    ProductWithDetailAllOfFromJSON,
    ProductWithDetailAllOfFromJSONTyped,
    ProductWithDetailAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProductWithDetail
 */
export interface ProductWithDetail {
    /**
     * JANコード
     * @type {string}
     * @memberof ProductWithDetail
     */
    code: string;
    /**
     * 商品名
     * @type {string}
     * @memberof ProductWithDetail
     */
    name: string | null;
    /**
     * 規格名
     * @type {string}
     * @memberof ProductWithDetail
     */
    specName: string | null;
    /**
     * 予測精度
     * @type {string}
     * @memberof ProductWithDetail
     */
    accuracy: ProductWithDetailAccuracyEnum;
    /**
     * 
     * @type {ModelType}
     * @memberof ProductWithDetail
     */
    modelType: ModelType;
    /**
     * 調整アラートの有無
     * @type {boolean}
     * @memberof ProductWithDetail
     */
    isNeedAdjustment: boolean;
    /**
     * 需要予測データの有無
     * @type {boolean}
     * @memberof ProductWithDetail
     */
    hasDemandInsightData: boolean;
    /**
     * 売価
     * @type {number}
     * @memberof ProductWithDetail
     */
    price: number | null;
    /**
     * 店発単
     * @type {number}
     * @memberof ProductWithDetail
     */
    minNumberOfProductsPerDelivery: number | null;
    /**
     * 入数
     * @type {number}
     * @memberof ProductWithDetail
     */
    quantityPerCase: number;
    /**
     * M3
     * @type {number}
     * @memberof ProductWithDetail
     */
    m3: number;
    /**
     * 店舗数
     * @type {number}
     * @memberof ProductWithDetail
     */
    numberOfStores: number;
    /**
     * 欠品許容ランク
     * @type {string}
     * @memberof ProductWithDetail
     */
    shortageToleranceRank: ProductWithDetailShortageToleranceRankEnum;
    /**
     * 
     * @type {{ [key: string]: InventoryPlan; }}
     * @memberof ProductWithDetail
     */
    inventoryPlanPerMdWeek: { [key: string]: InventoryPlan; };
    /**
     * 
     * @type {DemandAggregation}
     * @memberof ProductWithDetail
     */
    demandAggregation: DemandAggregation;
    /**
     * 終売日
     * @type {Date}
     * @memberof ProductWithDetail
     */
    endOfSalesAt: Date | null;
    /**
     * コメント
     * @type {string}
     * @memberof ProductWithDetail
     */
    comment: string | null;
}

/**
* @export
* @enum {string}
*/
export enum ProductWithDetailAccuracyEnum {
    High = 'high',
    Mid = 'mid',
    Low = 'low'
}/**
* @export
* @enum {string}
*/
export enum ProductWithDetailShortageToleranceRankEnum {
    S = 'S',
    A = 'A',
    B = 'B',
    C = 'C'
}

export function ProductWithDetailFromJSON(json: any): ProductWithDetail {
    return ProductWithDetailFromJSONTyped(json, false);
}

export function ProductWithDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductWithDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'specName': json['specName'],
        'accuracy': json['accuracy'],
        'modelType': ModelTypeFromJSON(json['modelType']),
        'isNeedAdjustment': json['isNeedAdjustment'],
        'hasDemandInsightData': json['hasDemandInsightData'],
        'price': json['price'],
        'minNumberOfProductsPerDelivery': json['minNumberOfProductsPerDelivery'],
        'quantityPerCase': json['quantityPerCase'],
        'm3': json['m3'],
        'numberOfStores': json['numberOfStores'],
        'shortageToleranceRank': json['shortageToleranceRank'],
        'inventoryPlanPerMdWeek': (mapValues(json['inventoryPlanPerMdWeek'], InventoryPlanFromJSON)),
        'demandAggregation': DemandAggregationFromJSON(json['demandAggregation']),
        'endOfSalesAt': (json['endOfSalesAt'] === null ? null : new Date(json['endOfSalesAt'])),
        'comment': json['comment'],
    };
}

export function ProductWithDetailToJSON(value?: ProductWithDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'specName': value.specName,
        'accuracy': value.accuracy,
        'modelType': ModelTypeToJSON(value.modelType),
        'isNeedAdjustment': value.isNeedAdjustment,
        'hasDemandInsightData': value.hasDemandInsightData,
        'price': value.price,
        'minNumberOfProductsPerDelivery': value.minNumberOfProductsPerDelivery,
        'quantityPerCase': value.quantityPerCase,
        'm3': value.m3,
        'numberOfStores': value.numberOfStores,
        'shortageToleranceRank': value.shortageToleranceRank,
        'inventoryPlanPerMdWeek': (mapValues(value.inventoryPlanPerMdWeek, InventoryPlanToJSON)),
        'demandAggregation': DemandAggregationToJSON(value.demandAggregation),
        'endOfSalesAt': (value.endOfSalesAt === null ? null : value.endOfSalesAt.toISOString().substr(0,10)),
        'comment': value.comment,
    };
}


